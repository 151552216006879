import React from "react"
//import { DiGithubBadge } from "react-icons/di"
//import { FaTwitter, FaLinkedin } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "/",
    title: "about",
  },
  {
    path: "/",
    title: "apps",
  },
//  {
//    path: "/#contact",
//    title: "contact",
//  },
]

export const socialMenuItems = [
//  {
//    icon: <FaLinkedin />,
//    url: "https://www.linkedin.com/in/morgan-baker-developer-inverness",
//    name: "LinkedIn",
//  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/tack090989",
    name: "Twitter",
  },
//  {
//    icon: <DiGithubBadge />,
//    url: "https://github.com/bagseye",
//    name: "GitHub",
//  },
]

export const footerMenuItems = [
  {
    path: "/privacy",
    title: "privacy",
  },
//  {
//    path: "/cookies",
//    title: "cookies",
//  },
]
