export const mainMenuItems = [
  {
    path: "/",
    text: "about",
  },
  {
    path: "/",
    text: "apps",
  },
//  {
//    path: "/",
//    text: "contact",
//  },
]
